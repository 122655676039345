<template>
  <div class="bg-white shadow-lg rounded-md px-4 mb-60">
    <div class="flex flex-row border-b">
      <div class="w-1/2 text-left">
        <div :class="`${largeOrangeText} col-span-12 py-4 border-b border-r`">Original Amount</div>
        <div class="text-xl font-semibold text-gray-600 border-r py-4">{{`$${record.originalAmount.toFixed(2)}`}}</div>
      </div>
      <div class="w-1/2 text-left">
        <div :class="`${largeOrangeText} col-span-12 py-4 border-b pl-4`">New Amount</div>
        <div class="text-xl font-semibold text-gray-600 pl-4 py-4">{{`$${record.newAmount.toFixed(2)}`}}</div>
      </div>
    </div>
    <div class="text-left border-b py-4 flex flex-row">
      <div :class="`${largeOrangeText}`">Error Code:</div>
      <div class="text-md mt-0.5 ml-5">{{ record.errorCode || "None" }}</div>
    </div>
    <div class="text-left py-4 flex flex-row">
      <div :class="`${largeOrangeText}`">Error Message:</div>
      <div class="text-md mt-0.5 ml-5">{{ record.statusCode || "None" }}</div>
    </div>
  </div>
</template>

<script>
import TransactionLogDetail from "./../../../mixin/TransactionLogDetail"

export default {
  name: "AcceptResetCard",
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  mixins: [TransactionLogDetail],
}
</script>

<style scoped>

</style>
