<template>
  <PageTemplate>
    <template slot="nav">
      <BasicNav>
        <template slot="nav-left">
          <div class="flex justify-start">
            <BackButton @click="goToAcceptLog"></BackButton>
          </div>
        </template>
        <template slot="nav-center">
          <div class="flex justify-center whitespace-nowrap">
            <h1 class="text-3xl">{{ title }}</h1>
          </div>
        </template>
      </BasicNav>
    </template>
    <template slot="body">
      <LoadingCard v-if="isLoading" />
      <ErrorCard v-else-if="errorFetching" message="Accept details not available"/>
      <AcceptResetCard v-else-if="isReset" :record="recordData"/>
      <CashAcceptDetailCard v-else :record="recordData" />
    </template>
  </PageTemplate>
</template>

<script>
import PageTemplate from "../../components/Helpers/Pages/PageTemplate";
import BasicNav from "../../components/Helpers/Navs/BasicNav";
import AcceptResetCard from "../../components/Helpers/Cards/AcceptResetCard";
import CashAcceptDetailCard from "../../components/Helpers/Cards/CashAcceptDetailCard";
import LoadingCard from "../../components/Helpers/Loading/LoadingCard";
import ErrorCard from "../../components/Helpers/Pages/ErrorCard";
import BackButton from "../../components/Helpers/Buttons/BackButton";
import {mapActions, mapState} from "vuex";

export default {
  name: "AcceptorLogDetail",
  components: { PageTemplate, BasicNav, AcceptResetCard, ErrorCard, LoadingCard, CashAcceptDetailCard, BackButton },
  data() {
    return {
      isLoading: false,
      errorFetching: false,
      isReset: false,
    };
  },
  computed: {
    ...mapState("AcceptDetail", ["data"]),
    recordData() {
      return this.data;
    },
    title() {
      return this.isReset ? "Cash Acceptor Reset Detail" : "Cash Acceptor Purchase Detail";
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions("AcceptDetail", ["fetchTransaction", "fetchEmpty"]),
    async fetchData() {
      this.isLoading = true;
      const id = this.$route.query.id;
      this.isReset = !!this.$route.query.reset;
      try {
        if (!this.isReset) {
          await this.fetchTransaction(id);
        } else {
          await this.fetchEmpty(id);
        }
      } catch (e){
        this.errorFetching = true;
      } finally {
        this.isLoading = false;
      }
    },
    async goToAcceptLog() {
      await this.$router.push({name: "AcceptorLogs"});
    },
  }
}
</script>

<style>
</style>
