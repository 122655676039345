<template>
  <div class="bg-white shadow-lg rounded-md px-4 mb-60">
    <div class="grid grid-cols-10 text-left self-center">
      <div :class="`${largeOrangeText} col-span-3 border-b border-r py-3`">Confirmation #</div>
      <div :class="`${largeOrangeText} col-span-3 border-b border-r py-3 pl-3`">Transaction Amount</div>
      <div :class="`${largeOrangeText} col-span-4 border-b py-3 pl-3`">Change Dispensed</div>
      <div class="text-md col-span-3 border-b border-r py-3 cashAcceptDetailConfirmationNumber">{{ record.confirmationNumber }}</div>
      <div class="text-md col-span-3 border-b border-r py-3 pl-3 cashAcceptDetailTransactionAmount">{{ currencyFormat(record.purchaseCost) }}</div>
      <div class="text-md col-span-4 border-b py-3 pl-3 cashAcceptDetailChangeDue">{{ currencyFormat(changeDue) }}</div>
    </div>
    <div class="grid grid-cols-12 text-left self-center">
      <div :class="`${largeOrangeText} col-span-12 py-3`">Cash Accepted</div>
      <div class="flex col-span-12 divide-x">
        <DenominationValueCell
            v-for="(value, index) in getAcceptorDenominations"
            :key="`entered-${index}`"
            :label="value.label"
            :value="getCashEnteredByDenomination(value.denomination)"
            class="flex-1"
        />
      </div>
    </div>
    <div class="text-left border-b py-4 flex flex-row">
      <div :class="`${largeOrangeText}`">Error Code:</div>
      <div class="text-md mt-0.5 ml-5 cashAcceptDetailStatusCode">{{ record.statusCode || "None" }}</div>
    </div>
    <div class="text-left py-4 flex flex-row">
      <div :class="`${largeOrangeText}`">Error Message:</div>
      <div class="text-md mt-0.5 ml-5 cashAcceptDetailErrorMessage">{{ record.description || "None" }}</div>
    </div>
  </div>

</template>

<script>
import DenominationValueCell from "../DenominationValueCell";
import TransactionLogDetail from "../../../mixin/TransactionLogDetail";
import _ from "lodash";
import currency from "currency.js";

export default {
  name: "CashPurchaseDetailCard",
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  mixins: [TransactionLogDetail],
  components: { DenominationValueCell },
  computed: {
    changeDue() {
      return this.record.cashDispensed.reduce((temp,currentValue) => temp + currentValue.total, 0);
    },
  },
  methods: {
    getCashEnteredByDenomination(denomination) {
      const total = _.find(this.record["cashEntered"], {denomination: denomination})?.total;
      return total ? currency(total).format() : currency(0).format();
    },
  },
}
</script>

<style scoped>

</style>
